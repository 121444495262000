.carousel-container {
  width: 100vw;
  height: inherit;
}
.carousel-container ul {
  height: inherit;
}
.carousel-container li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
