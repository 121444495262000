body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2c3138;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.delete-transition-enter {
  opacity: 0;
}

.delete-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.delete-transition-exit {
  opacity: 1;
}

.delete-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
