.react-contexify {
  background-color: #535562;
  box-sizing: border-box;
  box-shadow: -8px -2px 16px 0 rgba(43, 42, 42, 0.5);
  border-radius: 5px;
  z-index: 150;
  padding-top: 0;
  padding-bottom: 0;
}

.react-contexify__item {
  cursor: pointer;
  height: 2.5em;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  color: white;
  background-color: #636572;
  height: 2em;
  width: 100%;
  border-radius: 5px;
}

.react-contexify__item__content {
  color: white;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.875em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
