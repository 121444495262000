.react-datepicker {
  font-family: 'Montserrat', sans-serif;
  background-color: #535562;
  text-transform: uppercase;
  border: none;
  box-shadow: 0 27px 56px 1px rgba(43, 42, 42, 0.2);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.8em;
  width: 98.5%;
}

@media (max-width: 560px) {
  .react-datepicker {
    font-size: 0.75em;
    width: auto;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 0.75rem;
  }
}

.react-datepicker__header {
  background-color: #535562;
  border-bottom: none;
  padding-top: 12px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.react-datepicker__navigation--previous {
  outline: none;
}

.react-datepicker__navigation--next {
  outline: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f29312;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #f29312;
}

.react-datepicker__week-number {
  margin: 0;
}

.react-datepicker__day-names {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-family: 'Montserrat';
  font-weight: 900;
  height: 2em;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  margin-top: 0;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range,
.react-datepicker__year-container-text--selected,
.react-datepicker__year-container-text--in-selecting-range,
.react-datepicker__year-container-text--in-range {
  background-color: #f29312;
  color: #fff;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover,
.react-datepicker__year-container-text--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f29312;
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #f29312;
  color: #fff;
  border-radius: 0;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #f29312;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: transparent;
  border-radius: 0;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #f29312;
}

.react-datepicker__close-icon::after {
  background-color: #f29312;
}

.react-datepicker__day {
  border: 1px solid;
  margin-top: 0;
  margin-left: 0;
  margin-right: -1px;
  margin-bottom: -1px;
  outline: none;
}

.react-datepicker__day--outside-month {
  color: #9198a0;
  border-color: white;
}

.react-datepicker__month-container {
  width: 100%;
}
