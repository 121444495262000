.rc-dropdown {
  position: fixed;
  top: 0px !important;
  width: 100%;
  font-family: 'Roboto Condensed Bold';
  font-size: 1em;
  line-height: 1em;
}

.rc-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: initial;
  margin: 0;
}

.rc-dropdown-menu:before {
  position: initial;
  top: 0;
}

.rc-dropdown-menu > .rc-dropdown-menu-item {
  position: initial;
}

.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  position: initial;
  top: 0;
}
